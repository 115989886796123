// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bkrZw8ddriB8RldnXSGO {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: end;
    /* position: fixed;
    bottom: 35%;
    padding-left: 20px; */
}

.bkrZw8ddriB8RldnXSGO span {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #050A1482;
    cursor: pointer;
}

@media (max-width: 1240px) {

    .bkrZw8ddriB8RldnXSGO {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        position: fixed;
        right: 50px;
        top: 80vh;
        border-radius: 50%;
        background: rgba(237, 234, 234, 0.2);
        backdrop-filter: blur(3px);
        z-index: 10;
    }

    .bkrZw8ddriB8RldnXSGO span {
        display: none;
    }
}

@media (max-width: 480px) {
    .bkrZw8ddriB8RldnXSGO {
        right: 19px;
        top: 69vh;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Button/ButtonUp/ButtonUp.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB;;yBAEqB;AACzB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;;IAEI;QACI,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,WAAW;QACX,YAAY;QACZ,eAAe;QACf,WAAW;QACX,SAAS;QACT,kBAAkB;QAClB,oCAAoC;QACpC,0BAA0B;QAC1B,WAAW;IACf;;IAEA;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,SAAS;IACb;AACJ","sourcesContent":[".upBtnWrapper {\n    margin-top: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: end;\n    /* position: fixed;\n    bottom: 35%;\n    padding-left: 20px; */\n}\n\n.upBtnWrapper span {\n    font-size: 16px;\n    font-weight: 500;\n    text-transform: uppercase;\n    color: #050A1482;\n    cursor: pointer;\n}\n\n@media (max-width: 1240px) {\n\n    .upBtnWrapper {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        width: 50px;\n        height: 50px;\n        position: fixed;\n        right: 50px;\n        top: 80vh;\n        border-radius: 50%;\n        background: rgba(237, 234, 234, 0.2);\n        backdrop-filter: blur(3px);\n        z-index: 10;\n    }\n\n    .upBtnWrapper span {\n        display: none;\n    }\n}\n\n@media (max-width: 480px) {\n    .upBtnWrapper {\n        right: 19px;\n        top: 69vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upBtnWrapper": `bkrZw8ddriB8RldnXSGO`
};
export default ___CSS_LOADER_EXPORT___;
