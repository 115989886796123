// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vIjqAqp7HW_wIUpjhdlz {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.2px;
    background-color: rgba(199, 199, 204, 1);
    position: relative;
}

.T1tPbvB9qnapqVJpej3f {
    background-color: rgba(0, 122, 255, 1);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4px;
}

.aAmflI5rIdToih2TSqcc {
    position: relative;
    z-index: 1;
}

.zMeX_fWJRxWgNuF9eHDG {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.AOi2vZ97IavNJluOy6I1 {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    letter-spacing: 0.1px;
    white-space: nowrap;
}

.tLl0UrIKdE87A9Lbr99g {
    background-color: #0ACF6B;
    padding: 3px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 15px;
    letter-spacing: 0.1px;
    border-radius: 8px;
    font-weight: 600;
    height: 26px;
    white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/StartupCardFooter/StartupCardFooter.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,wCAAwC;IACxC,kBAAkB;AACtB;;AAEA;IACI,sCAAsC;IACtC,YAAY;IACZ,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".startup_stepsLine {\n    width: 100%;\n    height: 20px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 4px;\n    color: #fff;\n    font-size: 15px;\n    font-weight: 600;\n    letter-spacing: 0.2px;\n    background-color: rgba(199, 199, 204, 1);\n    position: relative;\n}\n\n.startup_stepsLine_fill {\n    background-color: rgba(0, 122, 255, 1);\n    height: 100%;\n    position: absolute;\n    left: 0;\n    top: 0;\n    border-radius: 4px;\n}\n\n.startup_lineText {\n    position: relative;\n    z-index: 1;\n}\n\n.startup_money {\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    gap: 10px;\n    margin-top: 10px;\n}\n\n.startup_title {\n    font-size: 15px;\n    font-weight: 600;\n    color: #000000;\n    letter-spacing: 0.1px;\n    white-space: nowrap;\n}\n\n.startup_money_count {\n    background-color: #0ACF6B;\n    padding: 3px 13px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #fff;\n    font-size: 15px;\n    letter-spacing: 0.1px;\n    border-radius: 8px;\n    font-weight: 600;\n    height: 26px;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"startup_stepsLine": `vIjqAqp7HW_wIUpjhdlz`,
	"startup_stepsLine_fill": `T1tPbvB9qnapqVJpej3f`,
	"startup_lineText": `aAmflI5rIdToih2TSqcc`,
	"startup_money": `zMeX_fWJRxWgNuF9eHDG`,
	"startup_title": `AOi2vZ97IavNJluOy6I1`,
	"startup_money_count": `tLl0UrIKdE87A9Lbr99g`
};
export default ___CSS_LOADER_EXPORT___;
