import { AdminModeratorAPI, bookmarkAPI, setActive, setErrorMessageToUserForModal, setMessageToUserForModal, setModal, useAppDispatch, useMutation, vacanciesApi } from "../cardImports";
import { clicksAPI, followOrUnFollowAPI } from "../../../api/API";
import { useQueryClient } from "react-query";
export var useUnsubscribeMutationInCard = function () {
    var dispatch = useAppDispatch();
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (id) { return followOrUnFollowAPI.unsubscribeOnCompany(id); },
        onSuccess: function () {
            //обновляются данные в разделе закладок
            Promise.all([
                queryClient.invalidateQueries("fetchPublicationOnMainPage"),
                queryClient.invalidateQueries("fetchAllStartups"),
                queryClient.invalidateQueries("BookmarkedPublicationInProfile"),
                queryClient.invalidateQueries("BookmarkedEventsInProfile"),
                queryClient.invalidateQueries("fetchEventsOnEventsPage"),
                queryClient.invalidateQueries("fetchPublicationsOnCompanyPage"),
                queryClient.invalidateQueries("fetchArticlesOnArticlesPage"),
                queryClient.invalidateQueries("FollowsInProfile"),
                queryClient.invalidateQueries("PublicationsInProfile"),
                queryClient.invalidateQueries("EventsInProfile"),
                //for profiles view
                queryClient.invalidateQueries("fetchExpertFeedInProfileView"),
                queryClient.invalidateQueries("fetchUserFeedOnCompanyView"),
                queryClient.invalidateQueries("fetchRightSetInCompanyView"),
                queryClient.invalidateQueries("fetchRightSetInExpertView"),
                queryClient.invalidateQueries("fetchRightSetInUserView"),
                queryClient.invalidateQueries("fetchInfoAboutExpert"),
                queryClient.invalidateQueries("fetchInfoAboutCompany"),
                queryClient.invalidateQueries("fetchInfoAboutUser"),
            ]);
        },
        onError: function (err) {
            var _a;
            if (err) {
                dispatch(setErrorMessageToUserForModal((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data));
                dispatch(setModal("error-alert-modal"));
                dispatch(setActive(true));
            }
            console.log("error invalidate bookmark", err);
        },
    });
};
export var useSubscribeMutationInCard = function () {
    var dispatch = useAppDispatch();
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (id) { return followOrUnFollowAPI.subscribeOnCompany(id); },
        onSuccess: function () {
            //обновляются данные в разделе закладок
            Promise.all([
                queryClient.invalidateQueries("fetchPublicationOnMainPage"),
                queryClient.invalidateQueries("fetchAllStartups"),
                queryClient.invalidateQueries("BookmarkedPublicationInProfile"),
                queryClient.invalidateQueries("BookmarkedEventsInProfile"),
                queryClient.invalidateQueries("fetchEventsOnEventsPage"),
                queryClient.invalidateQueries("fetchPublicationsOnCompanyPage"),
                queryClient.invalidateQueries("fetchArticlesOnArticlesPage"),
                queryClient.invalidateQueries("FollowsInProfile"),
                queryClient.invalidateQueries("PublicationsInProfile"),
                queryClient.invalidateQueries("EventsInProfile"),
                //for profiles view
                queryClient.invalidateQueries("fetchExpertFeedInProfileView"),
                queryClient.invalidateQueries("fetchUserFeedOnCompanyView"),
                queryClient.invalidateQueries("fetchRightSetInCompanyView"),
                queryClient.invalidateQueries("fetchRightSetInExpertView"),
                queryClient.invalidateQueries("fetchRightSetInUserView"),
                queryClient.invalidateQueries("fetchInfoAboutExpert"),
                queryClient.invalidateQueries("fetchInfoAboutCompany"),
                queryClient.invalidateQueries("fetchInfoAboutUser"),
            ]);
        },
        onError: function (err) {
            var _a;
            if (err) {
                dispatch(setErrorMessageToUserForModal((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data));
                dispatch(setModal("error-alert-modal"));
                dispatch(setActive(true));
            }
            console.log("error invalidate bookmark", err);
        },
    });
};
export var useBookmarkMutationInCard = function () {
    var dispatch = useAppDispatch();
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (id) { return bookmarkAPI.setBookmarkPost(id); },
        onMutate: function (param) { },
        onSuccess: function () {
            //обновляются данные в разделе закладок
            Promise.all([
                queryClient.invalidateQueries("fetchPublicationOnMainPage"),
                queryClient.invalidateQueries("BookmarkedPublicationInProfile"),
                queryClient.invalidateQueries("BookmarkedEventsInProfile"),
                queryClient.invalidateQueries("fetchEventsOnEventsPage"),
                queryClient.invalidateQueries("fetchPublicationsOnCompanyPage"),
                queryClient.invalidateQueries("fetchArticlesOnArticlesPage"),
                queryClient.invalidateQueries("PublicationsInProfile"),
                queryClient.invalidateQueries("EventsInProfile"),
                queryClient.invalidateQueries("FollowsInProfile"),
                queryClient.invalidateQueries("fetchUserFeedOnCompanyView"),
                queryClient.invalidateQueries("fetchExpertFeedInProfileView"),
                queryClient.invalidateQueries("fetchRightSetInCompanyView"),
                queryClient.invalidateQueries("fetchRightSetInExpertView"),
            ]);
        },
        onError: function (err) {
            var _a;
            if (err) {
                dispatch(setErrorMessageToUserForModal((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data));
                dispatch(setModal("error-alert-modal"));
                dispatch(setActive(true));
            }
            console.log("error invalidate bookmark", err);
        },
    });
};
export var useBookmarkVacancyMutationInCard = function () {
    var dispatch = useAppDispatch();
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (id) { return vacanciesApi.setBookmarkVacancy(id); },
        onSuccess: function () {
            Promise.all([queryClient.invalidateQueries("BookmarkedVacanciesInProfile"), queryClient.invalidateQueries("fetchVacanciesOnVacancyPage")]);
        },
        onError: function (err) {
            var _a;
            if (err) {
                dispatch(setErrorMessageToUserForModal((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data));
                dispatch(setModal("error-alert-modal"));
                dispatch(setActive(true));
            }
            console.log("error invalidate bookmark", err);
        },
    });
};
export var usePinPublicationMutationInCard = function () {
    var dispatch = useAppDispatch();
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (param) { return AdminModeratorAPI.getPinnedPublication(param === null || param === void 0 ? void 0 : param.id, param === null || param === void 0 ? void 0 : param.order); },
        onSuccess: function (res) {
            Promise.all([
                queryClient.invalidateQueries("fetchPublicationOnMainPage"),
                queryClient.invalidateQueries("fetchEventsOnEventsPage"),
                queryClient.invalidateQueries("fetchVacanciesOnVacancyPage"),
                queryClient.invalidateQueries("fetchPublicationsOnCompanyPage"),
                queryClient.invalidateQueries("fetchArticlesOnArticlesPage"),
            ]);
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        },
        onError: function (err) {
            var _a;
            if (err) {
                dispatch(setErrorMessageToUserForModal((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data));
                dispatch(setModal("error-alert-modal"));
                dispatch(setActive(true));
            }
            console.log("error invalidate bookmark", err);
        },
    });
};
export var useRepoClickMutationInCard = function () {
    var dispatch = useAppDispatch();
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (repoBody) { return clicksAPI.repoClick(repoBody); },
        onSuccess: function () {
            Promise.all([
                queryClient.invalidateQueries("fetchPublicationOnMainPage"),
                queryClient.invalidateQueries("BookmarkedPublicationInProfile"),
                queryClient.invalidateQueries("BookmarkedEventsInProfile"),
                queryClient.invalidateQueries("fetchEventsOnEventsPage"),
                queryClient.invalidateQueries("BookmarkedVacanciesInProfile"),
                queryClient.invalidateQueries("fetchVacanciesOnVacancyPage"),
                queryClient.invalidateQueries("fetchPublicationsOnCompanyPage"),
                queryClient.invalidateQueries("fetchArticlesOnArticlesPage"),
                queryClient.invalidateQueries("PublicationsInProfile"),
                queryClient.invalidateQueries("EventsInProfile"),
                queryClient.invalidateQueries("FollowsInProfile"),
                queryClient.invalidateQueries("fetchUserFeedOnCompanyView"),
                queryClient.invalidateQueries("fetchExpertFeedInProfileView"),
                queryClient.invalidateQueries("fetchRightSetInCompanyView"),
                queryClient.invalidateQueries("fetchRightSetInExpertView"),
            ]);
        },
        onError: function (err) {
            // if (err) {
            //   dispatch(setErrorMessageToUserForModal(err?.response?.data));
            //   dispatch(setModal("error-alert-modal"));
            //   dispatch(setActive(true));
            // }
            console.log("error invalidate bookmark", err);
        },
    });
};
export var usePinPublicationInModalMutationInCard = function () {
    var dispatch = useAppDispatch();
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (param) { return AdminModeratorAPI.getPinnedPublication(param === null || param === void 0 ? void 0 : param.id, param === null || param === void 0 ? void 0 : param.order, (param === null || param === void 0 ? void 0 : param.isPinned) ? null : param === null || param === void 0 ? void 0 : param.date); },
        onMutate: function (param) { },
        onSuccess: function (res) {
            Promise.all([
                queryClient.invalidateQueries("fetchPublicationOnMainPage"),
                queryClient.invalidateQueries("fetchEventsOnEventsPage"),
                queryClient.invalidateQueries("fetchVacanciesOnVacancyPage"),
                queryClient.invalidateQueries("fetchPublicationsOnCompanyPage"),
                queryClient.invalidateQueries("fetchArticlesOnArticlesPage"),
            ]);
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        },
        onError: function (err) {
            var _a;
            if (err) {
                dispatch(setErrorMessageToUserForModal((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data));
                dispatch(setModal("error-alert-modal"));
                dispatch(setActive(true));
            }
            console.log("error invalidate bookmark", err);
        },
    });
};
